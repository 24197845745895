import React, { useEffect, useState } from "react";
import { TableContainer } from "@/components/shared/table/TableContainer";
import { MediaBadge } from "@/components/shared/media-badge";
import { sequenceService } from "@/service/content/sequence.service";
import { TableFooter } from "@/components/shared/table/TableFooter";

export type Asset = {
  id: string;
  originalName: string;
  mediaType: string;
  sequence: {
    pathId: number;
    pathTitle: string;
    moduleId: number;
    moduleTitle: string;
  };
};

export const AssetTable = ({
  data: { assets, activeAsset, totalItems, totalPage },
  paging: { page, onPageChange },
  setActiveAsset,
  generatingAssetIds,
}: any) => {
  const [activeRowIndex, setActiveRowIndex] = useState<number | null>(0);

  const columns = [
    {
      accessor: "mediaType",
      header: "Type",
      width: "20%",
      cell: (row: any) => (
        <>
          {generatingAssetIds.includes(+row?.id) ? (
            <></>
          ) : (
            <MediaBadge type={row?.mediaType} />
          )}
        </>
      ),
    },
    {
      accessor: "originalName",
      header: "Name",
      width: "60%",
      cell: (row: any) => (
        <div className="capitalize cursor-pointer">{row?.originalName}</div>
      ),
    },
    {
      accessor: "qoosCount",
      header: "Qoos",
      width: "15%",
      cell: (row: any) => (
        <div className="capitalize">
          {(+(row?.qoosCount as any) ?? 0) === 0 ? "" : (row?.qoosCount as any)}
        </div>
      ),
    },
  ];

  const handleRowClick = (row: any, index: number) => {
    setActiveAsset(row);
    console.log(index);
    setActiveRowIndex(index);
  };

  return (
    <div className="w-full max-h-[calc(100vh-40px)] pr-[12px]">
      <div className="w-full max-h-[calc(100vh-200px)] overflow-y-auto pr-[12px]">
        <TableContainer
          columns={columns}
          data={assets}
          onRowClick={handleRowClick}
          activeRowIndex={activeRowIndex}
        />
        <TableFooter
          totalItems={totalItems}
          totalPage={totalPage}
          pageNum={page}
          onChange={(page) => {
            onPageChange(page);
            setActiveRowIndex(0);
          }}
        />
      </div>
    </div>
  );
};
