import { Icons } from "@/components/icons";
import { Badge } from "@/components/third-party/shadcn/badge";
import { Button } from "@/components/third-party/shadcn/button";
import { toast } from "@/components/third-party/shadcn/use-toast";
import { ContentAsset } from "@/models/asset.model";
import { assetService } from "@/service/content/asset.service";
import { chunkService } from "@/service/content/chunk.service";
import { transcriptService } from "@/service/content/transcript.service";
import { setMessage } from "@/store";
import { Separator } from "@radix-ui/react-dropdown-menu";
import { Loader2 } from "lucide-react";
import { useState, useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";

type GenerateStatus = "not_started" | "in_progress" | "complete" | "error";

export const AssetGenerateDrawer = ({
  asset,
  generatingAssets,
  onGenerate,
  onGenerateComplete,
}: {
  asset: ContentAsset;
  generatingAssets: ContentAsset[];
  onGenerate: any;
  onGenerateComplete: any;
}) => {
  const dispatch = useDispatch();

  const [quickGenerateInProgress, setQuickGenerateInProgress] = useState(false);
  const [generatingComplete, setGeneratingComplete] = useState(false);

  const inProgress = useMemo(() => {
    return (
      !!generatingAssets.find((a) => a?.id === asset?.id) ||
      quickGenerateInProgress
    );
  }, [generatingAssets, asset, quickGenerateInProgress]);

  const [transcriptStatus, setTranscriptStatus] = useState(
    "not_started" as GenerateStatus
  );
  const [chunkStatus, setChunkStatus] = useState(
    "not_started" as GenerateStatus
  );
  const [qooStatus, setQooStatus] = useState("not_started" as GenerateStatus);

  const handleConvertMediaToTranscript = async () => {
    setTranscriptStatus("in_progress");

    // If transcript exists return it
    const existingTranscript = await transcriptService.get(asset?.id);
    if (existingTranscript) {
      setTranscriptStatus("complete");
      return existingTranscript;
    }

    const generator = transcriptService.transcriptGenerator(asset?.id);

    let result = "";
    for await (const chunk of generator) {
      console.log(chunk); // Log each chunk to the console
      result += chunk;
    }

    await transcriptService.save(asset?.id, result);
    return result;
  };

  const handleQuickGenerate = async () => {
    setQuickGenerateInProgress(true);
    onGenerate(asset);
    setTranscriptStatus("in_progress");
    setChunkStatus("not_started");
    setQooStatus("not_started");

    try {
      dispatch(
        setMessage({
          show: true,
          message: "Generating Transcripts, Chunks, and more...",
        })
      );
      const transcript = await handleConvertMediaToTranscript()
        .then((transcript) => {
          setTranscriptStatus("complete");
          return transcript;
        })
        .catch(() => {
          setTranscriptStatus("error");
          setQuickGenerateInProgress(false);
          throw new Error("Failed to convert media to transcript");
        });

      // Set Qoo status here to give illusion that chunk and qoo generation is happening at the same time
      setQooStatus("in_progress");

      setChunkStatus("in_progress");
      await assetService
        .createAllResources(asset?.id, asset?.stream?.id)
        .then(() => {
          setChunkStatus("complete");
          setQooStatus("complete");
        });
    } catch (err) {
      toast({
        title: "Something went wrong",
        description: err.message,
      });
    } finally {
      setQuickGenerateInProgress(false);
      setGeneratingComplete(true);
      onGenerateComplete(asset);

      dispatch(
        setMessage({
          show: true,
          closable: true,
          message: "Asset generation process complete.",
        })
      );
    }
  };

  return (
    <div
      className={`flex flex-col flex-1 gap-4 min-w-[450px] p-4 border-[1px] rounded-lg border-gray-200`}
    >
      {asset?.uploaded && !inProgress ? (
        <>
          <div className="flex flex-col gap-2 items-center w-full justify-center h-full">
            <div className="font-semibold">This asset has no Qoos</div>
            <div className="text-[14px] mb-[16px]">
              Quickly generate Qoos by clicking the button below.
            </div>
            <Button
              size="sm"
              onClick={handleQuickGenerate}
              disabled={inProgress}
            >
              <Icons.Wand fill="white" />
              {inProgress ? "Generating..." : "Quick Generate"}
            </Button>
          </div>
        </>
      ) : (
        <></>
      )}

      {(inProgress || generatingComplete) &&
        !!generatingAssets.find((a) => a?.id === asset?.id) && (
          <div>
            <Separator />
            <div className="flex flex-row items-center font-semibold text-[16px] my-[12px]">
              {inProgress && <Loader2 className="mr-2 h-6 w-6 animate-spin" />}
              <div>{inProgress ? "Generating..." : "Completion Result"}</div>
            </div>
            <div className="flex justify-between items-center my-2">
              <span>Transcripts</span>
              <QuickGenerateBadge status={transcriptStatus} />
            </div>
            {/* <div className="flex justify-between items-center my-2">
              <span>Chunks</span>
              <QuickGenerateBadge status={chunkStatus} />
            </div> */}
            <div className="flex justify-between items-center my-2">
              <span>Qoos</span>
              <QuickGenerateBadge status={qooStatus} />
            </div>
          </div>
        )}
    </div>
  );
};

const QuickGenerateBadge = ({ status }) => {
  const [statusClass, setStatusClass] = useState("outline" as any);

  useEffect(() => {
    const getStatusClass = (status) => {
      switch (status) {
        case "not_started":
          return "outline";
        case "in_progress":
          return "secondary";
        case "error":
          return "destructive";
        case "complete":
          return "default";
        default:
          return "outline";
      }
    };

    setStatusClass(getStatusClass(status));
  }, [status]);

  return (
    <Badge variant={statusClass}>
      {status === "in_progress" ? (
        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
      ) : (
        <></>
      )}
      {status}
    </Badge>
  );
};
