"use client";

import { useEffect, useState } from "react";
import { Button } from "@/components/base/Button";
import { Icons } from "@/components/icons/CustomIcons";
import { QooPreviewDrawer } from "@/components/drawer/QooPreviewDrawer";
import { QooEditDrawer } from "@/components/drawer/QooEditDrawer";
import { QooModel } from "@/models/qoo.model";
import { ContentAsset } from "@/models/asset.model";
import { QooAddDrawer } from "@/components/drawer/QooAddDrawer";
import { toast } from "@/components/third-party/shadcn/use-toast";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { qooService } from "@/service/qoo/qoo.service";

interface Props {
  asset: ContentAsset;
  handleChange?: () => void;
}

const SortableItem = ({ id, children }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <li
      ref={setNodeRef}
      style={style}
      className={`bg-white py-1 flex items-center ${isDragging ? "border border-gray-200 rounded" : ""}`}
    >
      <div {...attributes} {...listeners} className="cursor-move">
        <Icons.GripVertical size={16} className="text-gray-400" />
      </div>
      {children}
    </li>
  );
};

export const QoosListSection = ({ asset, handleChange }: Props) => {
  const [qoos, setQoos] = useState([] as QooModel[]);
  const [loading, setLoading] = useState(false);

  const [activeQoo, setActiveQoo] = useState(null);
  const [activeQooAction, setActiveQooAction] = useState(
    null as "preview" | "edit" | "add" | null
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleAddQoo = async (qoo: QooModel) => {
    setQoos((prevQoos) => {
      const qooExists = prevQoos.some((q) => q.id === qoo.id);

      if (qooExists) {
        return prevQoos.map((q) => (q.id === qoo.id ? { ...q, ...qoo } : q));
      } else {
        return [...prevQoos, { ...qoo, index: prevQoos.length }];
      }
    });

    handleChange();
  };

  const handleDeleteQoo = (qooId: number) => {
    setQoos((prevQoos) => {
      const updatedQoos = prevQoos.filter((qoo) => qoo.id !== qooId);
      return updatedQoos.map((qoo, index) => ({ ...qoo, index }));
    });
    setActiveQoo(null);
    setActiveQooAction(null);
    handleChange();
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setQoos((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        const newItems = arrayMove(items, oldIndex, newIndex);
        return newItems.map((item, index) => ({ ...item, index }));
      });

      handleQooSorted();
    }
  };

  const handleQooSorted = async () => {
    setLoading(true);
    try {
      await qooService.updateQoosStreamLinks(asset.stream.id, qoos);
      toast({ title: "Qoos order updated successfully" });
    } catch (error) {
      toast({ title: "Failed to update Qoos order, please try again" });
      // Optionally, you could revert the local state here if you want to ensure consistency with the backend
      // setQoos(previousQoosState);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!asset?.id) return;

    console.log(asset);
    const sortedQoos = asset?.stream?.qoos?.sort((a, b) => a.index - b.index);
    setQoos(sortedQoos);
  }, [asset]);

  return (
    <div className="w-full flex flex-col justify-between relative">
      <div className="flex flex-row items-center justify-between">
        <div className="text-[16px] flex flex-row gap-1 items-center">
          <Icons.Wand />
          <div className="text-[18px] font-semibold">Generated Qoos</div>
        </div>

        <Button
          onClick={() => {
            setActiveQoo(null);
            setActiveQooAction("add");
          }}
          className="w-auto"
        >
          + Create Qoo
        </Button>
      </div>

      <div className="flex flex-col mt-[12px]">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={qoos.map((qoo) => qoo.id)}
            strategy={verticalListSortingStrategy}
          >
            {qoos.map((qoo: any) => (
              <SortableItem key={qoo.id} id={qoo.id}>
                <div
                  className="flex flex-row gap-3 items-center cursor-pointer hover:bg-gray-200 p-2 rounded-lg w-full ml-2"
                  onClick={() => {
                    setActiveQoo(qoo);
                    setActiveQooAction("preview");
                  }}
                >
                  <div className="w-4"><Icons.Qoos /></div>
                  <div className="text-[14px] font-semibold">{qoo.title}</div>
                </div>
              </SortableItem>
            ))}
          </SortableContext>
        </DndContext>
      </div>

      <QooPreviewDrawer
        displayId
        displayTags
        onEdit={() => {
          setActiveQooAction("edit");
        }}
        onDelete={() => {
          if (activeQoo) {
            handleDeleteQoo(activeQoo.id);
          }
        }}
        key={activeQoo?.id}
        isOpen={!!activeQoo && activeQooAction === "preview"}
        onClose={() => {
          setActiveQoo(null);
          setActiveQooAction(null);
        }}
        qoos={qoos}
        index={
          activeQoo
            ? qoos.findIndex((qoo) => qoo.id === activeQoo.id)
            : undefined
        }
      />

      <QooEditDrawer
        id={activeQoo?.id}
        isOpen={!!activeQoo && activeQooAction === "edit"}
        onClose={() => {
          setActiveQoo(null);
          setActiveQooAction(null);
        }}
        onSubmit={(qoo) => {
          setQoos((prevQoos) =>
            prevQoos.map((q) => (q.id === qoo.id ? { ...q, ...qoo } : q))
          );
        }}
        onVideoGenerate={(qoo) => {
          setQoos((prevQoos) =>
            prevQoos.map((q) =>
              q.id === qoo.id
                ? {
                    ...q,
                    heygenVideo: {
                      ...(qoo.heygenVideo ?? {}),
                      status: "STARTED",
                    },
                  }
                : q
            )
          );
        }}
      />

      <QooAddDrawer
        s3MediaId={asset.id}
        streamId={asset.stream.id}
        qooIndex={qoos.length}
        isOpen={activeQooAction === "add"}
        onClose={() => {
          setActiveQooAction(null);
        }}
        onSubmit={handleAddQoo}
      />
    </div>
  );
};
