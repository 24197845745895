"use client";

import { useEffect, useState } from "react";
import { assetService } from "@/service/content/asset.service";
import { ContentAsset } from "@/models/asset.model";
import { AssetTable } from "./parts/AssetTable";
import { toast } from "@/components/third-party/shadcn/use-toast";
import { AssetDetailDrawer } from "./parts/drawer/DetailDrawer";
import { AssetGenerateDrawer } from "./parts/drawer/GenerateDrawer";
import { Input } from "@/components/third-party/shadcn/input";
import { FilterMenu } from "./parts/FilterMenu";

const PAGE_LIMIT = 30;
interface Filter {
  searchTerm: string;
  moduleId: number;
  pathId: number;
}

export function AssetLibrary() {
  const [assets, setAssets] = useState([] as ContentAsset[]);
  const [activeAsset, setActiveAsset] = useState<ContentAsset>(
    {} as ContentAsset
  );
  const [activeAssetQoosCount, setActiveAssetQoosCount] = useState(0);
  const [generatingAssets, setGeneratingAssets] = useState<ContentAsset[]>(
    [] as ContentAsset[]
  );

  const [filter, setFilter] = useState(null as Filter);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(PAGE_LIMIT);
  const [assetLoading, setAssetLoading] = useState(true);

  const fetchAssets = async (filter?: Filter) => {
    setAssetLoading(true);
    const {
      data: assets,
      totalItems,
      totalPage,
    } = await assetService
      .getList(
        {
          includePresignedUrl: true,
          ...(filter ?? {}),
        },
        { page, limit }
      )
      .finally(() => setAssetLoading(false));

    setTotalItems(totalItems);
    setTotalPage(totalPage);
    setAssets(assets);
    setActiveAsset(assets?.[0]);
    setActiveAssetQoosCount(assets?.[0]?.qoosCount);
  };

  const init = async (filter?: Filter) => {
    await fetchAssets(filter);
  };

  const handleAssetDeleted = (asset: ContentAsset) => {
    setAssets((prevAssets) => {
      const newAssets = prevAssets.filter((a) => a.id !== asset.id);

      // Update active asset
      setActiveAsset(() => newAssets[0] || undefined);

      // Update total items
      setTotalItems(newAssets.length);

      // Update total pages
      setTotalPage((prevPages) => {
        const newTotalPages = Math.ceil(newAssets.length / limit);
        return newTotalPages < prevPages ? newTotalPages : prevPages;
      });

      return newAssets;
    });
  };

  const refetchActiveAsset = async () => {
    const assets = await assetService.getList({
      mediaIds: [activeAsset?.id],
      includePresignedUrl: true,
    });

    const asset = assets?.data?.[0];
    if (!asset) {
      return;
    }

    setAssets((prev) => {
      const index = prev.findIndex((a) => a.id === asset.id);
      prev[index] = asset;
      return [...prev];
    });

    setActiveAsset((prev) => {
      return {
        ...prev,
        ...asset,
      };
    });

    setActiveAssetQoosCount(asset?.qoosCount);
  };

  const handleAssetClick = (asset: ContentAsset) => {
    if (activeAsset?.id === asset?.id) return;
    setActiveAsset(asset);
    setActiveAssetQoosCount(asset?.qoosCount);
  };

  const onFilterChange = (filter: { path: number; module: number }) => {
    setFilter((prev) => {
      return {
        ...prev,
        pathId: filter.path,
        moduleId: filter.module,
      };
    });
  };

  useEffect(() => {
    init();
  }, [page, limit]);

  useEffect(() => {
    if (!filter) {
      return;
    }

    setPage(1);
    init(filter);
  }, [filter]);

  return (
    <div className="flex flex-row flex-1 gap-4 max-h-[calc(100vh-40px)] overflow-y-auto ">
      <div className={`flex flex-row gap-2 w-full py-2`}>
        <div className="flex flex-col flex-1 gap-4 max-h-[calc(100vh-120px)] overflow-y-auto">
          <div className="flex items-center space-x-4 w-full mb-4">
            <h2 className="text-2xl font-semibold whitespace-nowrap">
              Asset Library
            </h2>
            <div className="flex-grow flex items-center space-x-2">
              <div className="flex-grow pt-1">
                <Input
                  placeholder="Search by Asset Name..."
                  value={filter?.searchTerm}
                  onChange={(event) => {
                    setFilter((prev) => {
                      return {
                        ...prev,
                        searchTerm: event.target.value,
                      };
                    });
                  }}
                  className="w-full h-10 px-3" // Adjust these values as needed
                />
              </div>
              <FilterMenu onApply={onFilterChange} />
            </div>
          </div>
          <AssetTable
            data={{
              assets,
              totalItems,
              totalPage,
              activeAsset,
            }}
            paging={{
              page,
              onPageChange: (page) => {
                setPage(page);
              },
            }}
            generatingAssetIds={generatingAssets.map((a) => a?.id)}
            setActiveAsset={handleAssetClick}
          />
        </div>
        <div className={`flex flex-col gap-4 ${activeAsset ? "" : "hidden"}`}>
          <AssetDetailDrawer
            asset={activeAsset}
            onActiveAssetChange={refetchActiveAsset}
            onDeleted={handleAssetDeleted}
          />

          {!activeAssetQoosCount && (
            <AssetGenerateDrawer
              asset={activeAsset}
              generatingAssets={generatingAssets}
              onGenerate={(asset) => {
                setGeneratingAssets((prev) => {
                  if (prev.find((a) => a?.id === asset?.id)) {
                    return prev;
                  }

                  return [...prev, asset];
                });
              }}
              onGenerateComplete={(asset) => {
                setGeneratingAssets((prev) => {
                  return prev.filter((a) => a?.id !== asset?.id);
                });

                toast({
                  title: `Transcripts, Chunks, and Qoos generated successfully.`,
                  description: `Asset generation for ${asset?.originalName} complete.`,
                });

                refetchActiveAsset();
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
